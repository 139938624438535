import { FC, useEffect, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { omit } from "lodash";

import ChatBubbleIcon from "icons/chat-bubble.svg?react";

import useDispatch from "hooks/redux/use-dispatch";

import { storageActions } from "store/storage";

import reviewsApi from "api/reviews";

import { Modal, openAlert, useDialog } from "components/dialog";
import Chips from "components/chips";

import Button from "basics/button";
import { Textarea } from "basics/input";
import Radio from "basics/radio";

import ChatSkeleton from "./components/chat-skeleton";
import {
  FEEDBACK_OPTIONS,
  MAX_COMMENT_LENGTH,
  PROVIDER_LOGOS,
} from "./duck/constants";
import { FeedbackValues, FormValues } from "./duck/types";

import classes from "./styles/classes.module.scss";

const FeedbackModal: FC<TransactionInfo> = ({
  providerName,
  transactionId,
}) => {
  const [isLoadingTags, setIsLoadingTags] = useState(false);
  const [tags, setTags] = useState([]);
  const {
    formState: { isSubmitting, errors },
    control,
    handleSubmit,
    setError,
    watch,
    register,
  } = useForm<FormValues>({
    defaultValues: { comment: "", selectedTags: [], rating: null },
  });
  const rating = watch("rating");
  const { resolve } = useDialog();
  const dispatch = useDispatch();

  const submitHandler: SubmitHandler<FormValues> = async ({
    comment,
    selectedTags,
    rating,
  }) => {
    if (comment.length > MAX_COMMENT_LENGTH) {
      return setError(
        "comment",
        {
          message: `Comment text should not contain more than ${MAX_COMMENT_LENGTH} symbols`,
        },
        { shouldFocus: true },
      );
    }

    const isSatisfied = rating === FeedbackValues.satisfied;

    try {
      await reviewsApi.createReview({
        transactionId,
        text: comment,
        rating: isSatisfied,
        tags: isSatisfied ? [] : selectedTags,
      });
      resolve(null);
      openAlert({
        message: `Thank you for helping us improve${
          isSatisfied
            ? ""
            : ". We will work on it and will reach out to you soon"
        }`,
        type: "success",
      });
    } catch {
      openAlert({
        message: "An error occurred while submitting the form",
      });
    }
  };

  useEffect(() => {
    dispatch(storageActions.set({ key: "transactionInfo", value: null }));
    loadTags();
  }, []);

  const loadTags = async () => {
    setIsLoadingTags(true);
    try {
      const tags = await reviewsApi.fetchTags(transactionId);
      setTags(tags);
    } catch {}
    setIsLoadingTags(false);
  };

  const ProviderLogoIcon = PROVIDER_LOGOS[providerName];

  return (
    <Modal>
      <Modal.Header>Share your feedback</Modal.Header>
      <form className={classes.form} onSubmit={handleSubmit(submitHandler)}>
        <div className={classes.feedbackIcon}>
          {ProviderLogoIcon ? (
            <div className={classes.providerLogoWrapper}>
              <ProviderLogoIcon />
            </div>
          ) : (
            <ChatSkeleton className={classes.providerLogoWrapper} />
          )}
          <ChatBubbleIcon />
          <ChatSkeleton />
        </div>
        <div className={classes.radioGroupWrapper}>
          <div className={classes.description}>
            What was your experience using the{" "}
            <p className={classes.providerName}>{providerName}</p>?
          </div>
          <Controller
            name="rating"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Radio
                {...omit(field, "ref")}
                className={classes.radioGroup}
                classNames={{ item: classes.radio }}
                items={FEEDBACK_OPTIONS}
                option={({ item: { value, icon: Icon } }) => (
                  <>
                    <Icon />
                    <p className={classes.text}>{value}</p>
                  </>
                )}
              />
            )}
          />
        </div>
        <div>
          <p className={classes.description}>Tell us more</p>
          {rating === FeedbackValues.unhappy && (
            <Controller
              name="selectedTags"
              control={control}
              render={({ field }) => (
                <Chips
                  {...omit(field, "ref")}
                  name="selectedTags"
                  className={classes.chips}
                  items={tags}
                  isLoading={isLoadingTags}
                />
              )}
            />
          )}
          <Textarea
            name="comment"
            {...register("comment")}
            error={errors.comment?.message}
            placeholder="Leave a comment"
          />
        </div>
        <Button
          disabled={!rating || Boolean(errors.comment)}
          className={classes.submitButton}
          themeName="primary"
          type="submit"
          isBlock
          isLoading={isSubmitting}
        >
          Submit
        </Button>
      </form>
    </Modal>
  );
};

export default FeedbackModal;
