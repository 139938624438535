import { FC } from "react";
import ProfileIcon from "icons/profile.svg?react";
import GearIcon from "icons/gear.svg?react";

import Button from "basics/button";

import { openProfileDrawer } from "modules/profile-drawer";
import { openSignInModal } from "modules/signin-modal";

import { useConnect } from "./duck/hooks";

import classes from "./styles/classes.module.scss";

interface Props {
  isLoadedAccount: boolean;
}

const Buttons: FC<Props> = ({ isLoadedAccount }) => {
  const { account, isSigning } = useConnect();

  if (!isLoadedAccount) {
    return <div className={classes.profileSkeleton} />;
  }

  if (account) {
    return (
      <Button
        className={classes.profileButton}
        themeName="default"
        onClick={() => openProfileDrawer()}
      >
        <ProfileIcon />
        <span className={classes.account}>{account.email}</span>
      </Button>
    );
  }

  return (
    <>
      <Button
        themeName="default"
        isIconOnly
        onClick={() => openProfileDrawer()}
      >
        <GearIcon />
      </Button>
      <Button
        themeName="primary"
        isLoading={isSigning}
        className={classes.signIn}
        onClick={() => openSignInModal()}
      >
        Sign In
      </Button>
    </>
  );
};

export default Buttons;
