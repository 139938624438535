import { FunctionComponent, SVGProps } from "react";

export type SVGIcons = Record<
  string,
  FunctionComponent<SVGProps<SVGSVGElement>>
>;

export const getProcessedSVGs = (importedIcons: SVGIcons) => {
  const icons: SVGIcons = {};
  Object.entries(importedIcons).forEach(([path, module]) => {
    const folders = path.split("/");
    const [name] = folders[folders.length - 1].split(".");
    icons[name] = module;
  });

  return icons;
};
