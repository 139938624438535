import HappyFaceIcon from "emojis/happy-face.svg?react";
import UnhappyFaceIcon from "emojis/unhappy-face.svg?react";

import { getProcessedSVGs, type SVGIcons } from "selectors/icons";

import { FeedbackValues } from "./types";

export const FEEDBACK_OPTIONS = [
  {
    value: FeedbackValues.satisfied,
    icon: HappyFaceIcon,
  },
  {
    value: FeedbackValues.unhappy,
    icon: UnhappyFaceIcon,
  },
];

const rawProviderIcons = import.meta.glob("icons/providers/*", {
  eager: true,
  import: "default",
  query: "?react",
}) as SVGIcons;

export const PROVIDER_LOGOS = getProcessedSVGs(rawProviderIcons);

export const MAX_COMMENT_LENGTH = 512;
